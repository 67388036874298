<template>
  <div class="blogger-more">
    <van-nav-bar :title="title" @click-left="onClickLeft" :border="false">
      <template #left>
        <svg-icon iconClass="leftArrow" class="leftArrow"></svg-icon>
      </template>
    </van-nav-bar>
    <!-- main -->
    <div class="blogger-main">
      <!-- top -->
      <div class="blogger-top-info">
        <div class="blogger-left">
          <div class="blogger-avatar">
            <ImgDecypt
              :src="
                mediaInfos[0] &&
                mediaInfos[0].author &&
                mediaInfos[0].author.avatarUrl
              "
              :round="true"
              class="avatar"
              :key="
                mediaInfos[0] &&
                mediaInfos[0].author &&
                mediaInfos[0].author.avatarUrl
              "
            />
            <img :src="avatarFrame" class="avatarFrame" />
          </div>
          <div class="blogger-name-info">
            <h2>{{ author.nickName ? author.nickName : "未知的粉丝团" }}</h2>
            <div class="blogger-praised">
              <span>粉丝&nbsp;{{ author.fanss | watchCount }}</span>
              <span>获赞&nbsp;{{ author.likes | watchCount }}</span>
            </div>
          </div>
        </div>
        <div
          :class="['blogger-focus-on', { 'blogger-focus-ok': author.cared }]"
          @click="focus"
        >
          {{ author.cared ? "已关注" : "关注" }}
        </div>
      </div>
      <!-- content -->
      <div class="video-content">
        <Pullrefresh
          @onLoad="onLoad"
          :loading="loading"
          :finished="finished"
          @onRefresh="onRefresh"
          :refreshing="refreshing"
          :isNoData="isNoData"
          :firstLoading="firstLoading"
        >
          <div
            v-for="(item, index) in mediaInfos"
            :key="index"
            @click="onJumpVideo(item)"
          >
            <div class="video-item">
              <div class="video-cover-box">
                <ImgDecypt :src="item.videoCover" class="video-cover" />
                <div class="video-info-timers flex-between">
                  <div class="left">
                    <span class="left-play"></span
                    >{{ item.watchTimes | watchCount }}
                  </div>
                  <div class="right">{{ item.playTime | videotime }}</div>
                </div>
                <CoveLabel class="cust_label" :item="item" />
              </div>
              <div class="video-info">
                <h2>{{ item.content }}</h2>
                <div class="video-tag-box" v-if="item.flags">
                  <div
                    class="video-tag"
                    v-for="(tag, index) in item.flags"
                    :key="index"
                  >
                    <span>{{ tag }}</span>
                  </div>
                </div>
              </div>
            </div>
            <AdvSwiper
              :advList="tagAd"
              v-if="(index + 1) % 4 == 0 && tagAd.length > 0"
              class="l_adv"
            />
          </div>
        </Pullrefresh>
      </div>
    </div>
  </div>
</template>
<script>
import { getUseDetail, sendUserCare } from "@/api/on_demand.js";
import { Toast } from "vant";
import Pullrefresh from "@/views/widget/PullRefresh.vue";
import CoveLabel from "@/components/CoveLabel/index.vue";
import { jumpVideo } from "@/utils/index.js";
import AdvSwiper from "@/components/AdvSwiper/index.vue";
import { getAdItem, AdType } from "@/utils/getAdv.js";
export default {
  components: {
    Pullrefresh,
    CoveLabel,
    AdvSwiper,
  },
  data() {
    return {
      loading: false, //下滑loading
      finished: false, //数据是否加载完毕
      refreshing: false, //下拉下载loading
      isNoData: false, //数据是否为空
      firstLoading: true, //是否第一次加载
      // umt 1：原创 2:动态视频 3：粉丝福利 4:动态帖子 0:无效的占位符
      req: { userID: 0, umt: 1, pageNum: 1, pageSize: 10 },
      mediaInfos: [],
      author: {}, //博主信息
      avatarFrame: require("@/assets/png/avatarFrame2.png"),
      title: this.$route.query.title,
      tagAd: [],
    };
  },
  created() {
    this.tagAd = getAdItem(AdType.columnAd);
    this.getData();
  },
  methods: {
    //上滑加载
    onLoad() {
      this.req.pageNum++;
      this.getData();
    },
    //刷新
    onRefresh() {
      this.refreshing = true;
      this.finished = false;
      this.req.pageNum = 1;
      this.mediaInfos = [];
      this.getData();
    },
    //获取数据
    async getData() {
      this.loading = true;
      let req = this.req;
      req.userID = Number(this.$route.query.id);
      let ret = await this.$Api(getUseDetail, this.req);

      this.loading = false;
      this.refreshing = false;
      this.firstLoading = false;

      if (ret.code == 200 && ret.data && ret.data.mediaInfos) {
        let mediaInfos = ret.data.mediaInfos;
        this.mediaInfos = this.mediaInfos.concat(mediaInfos);
        if (this.mediaInfos.length > 0) {
          this.author = this.mediaInfos[0].author;
        }

        if (mediaInfos.length == 0 && this.req.pageNum == 1) {
          this.isNoData = true;
          return;
        }
        if (mediaInfos.length < this.req.pageSize) {
          this.finished = true;
        }
      }
    },
    onJumpVideo(item) {
      jumpVideo(item);
    },
    //返回
    onClickLeft() {
      this.$router.go(-1);
    },
    // 关注
    async focus() {
      let isCared = this.author.cared;
      let req = {
        userId: Number(this.$route.query.id),
        isCare: !isCared,
      };
      let ret = await this.$Api(sendUserCare, req);
      if (ret.code == 200) {
        this.author.cared = !isCared;
        Toast(!isCared ? "关注成功" : "取消关注");
        return;
      }
      Toast(ret.tip || "关注失败");
    },
  },
};
</script>
<style lang="scss" scoped>
.blogger-more {
  .leftArrow {
    font-size: 16px;
  }
  .van-nav-bar__title {
    font-size: 16px;
  }
  /deep/ .van-nav-bar__content {
    background: $vermillion;
    color: #fff;
    border-bottom: 1px solid #d6d7d6;
  }
  /deep/ .van-nav-bar__title {
    color: #fff;
  }
  .blogger-main {
    margin: 10px;
    height: 100%;
    .blogger-top-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 10px;
      .blogger-left {
        display: flex;
        position: relative;
        .blogger-avatar {
          width: 70px;
          height: 70px;
          padding: 5px;
          box-sizing: border-box;
          position: relative;
          .avatarFrame {
            position: absolute;
            top: -2px;
            left: 0;
            width: 70px;
            height: 70px;
          }
        }
        .blogger-name-info {
          width: 170px;
          padding-left: 10px;
          padding-right: 10px;
          margin: auto;
          text-align: center;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          text-align: left;
          .blogger-praised {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            span {
              font-size: 13px;
              color: #252525;
            }
            span:first-child {
              padding-right: 10px;
            }
          }
          h2 {
            font-size: 20px;
            color: #2a2827;
            padding-bottom: 6px;
            font-weight: 400;
          }
        }
      }
      .blogger-focus-ok {
        background: #a0a0a0 !important;
      }
      .blogger-focus-on {
        width: 88px;
        height: 35px;
        line-height: 35px;
        flex-shrink: 0;
        text-align: center;
        color: #fff;
        background: red;
        border-radius: 18px;
        font-size: 16px;
      }
    }
    .video-content {
      overflow-y: auto;
      height: calc(100% - 130px);
      -webkit-overflow-scrolling: touch;
      .video-item {
        display: flex;
        width: 100%;
        height: 107px;
        flex-direction: row;
        margin: 10px 0;
        .video-cover-box {
          flex: 1;
          position: relative;
          .video-cover {
            width: 100%;
            height: 100%;
            overflow: hidden;
            /deep/ img {
              border-radius: 6px;
            }
          }
        }
        .video-info {
          flex: 1;
          height: 107px;
          padding-left: 10px;
          display: flex;
          flex-flow: column;
          justify-content: space-between;
          h2 {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3; //多行在这里修改数字即可
            /* autoprefixer: ignore next */
            -webkit-box-orient: vertical;
            font-size: 16px;
            font-weight: 400;
          }
          .video-tag-box {
            display: flex;
            span {
              padding-right: 5px;
              color: #6a6b66;
              font-size: 13px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
}
//图标
.cust_label {
  position: absolute;
  top: 0;
  right: 0;
}

//播放次数 时间
.cover-box {
  position: relative;
}
.video-info-timers {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 26px;
  align-items: center;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
  padding: 4px 8px;
  box-sizing: border-box;
  font-size: 10px;
  color: #ffffff;
  .left {
    display: flex;
    align-items: center;
    .left-play {
      display: inline-block;
      margin-right: 3px;
      width: 10px;
      height: 10px;
      background: url("../../../assets/png/play_icon.png") center center
        no-repeat;
      background-size: 100%;
    }
  }
}
.l_adv {
  height: 100px;
  overflow: hidden;
  /deep/ .swiper-container,/deep/ .swiperSlide {
    height: 100%;
  }
}
</style>
